import Vue from "vue";

const selectCidades: HTMLSelectElement | null = document.querySelector('#select-cidade');

if (selectCidades) {
    const SelectCidade = new Vue({
        el: selectCidades,
        data: {
            cidade: '',
            oldCidade: ''
        },
        methods: {
            onChangeHandle: function () {
                const cidadeAtual = this.filterCidadeAtual();
                window.location.href = window.location.href.replace(cidadeAtual, this.cidade)
            },
            filterCidadeAtual: function () {
                const expression: RegExp = /(^\/[\w\-]+\/?)/i;
                const match = expression.exec(window.location.pathname);
                if (match) {
                    return match[0].replaceAll('/', '');
                }
            }
        },
        mounted() {
            this.cidade = this.filterCidadeAtual();
            this.oldCidade = this.cidade;
        }
    });
}
