import axios from 'axios';
import { required, email, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { validationMessage } from "vuelidate-messages";
import Vue from 'vue';
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueRecaptcha from "vue-recaptcha";

type FornecedorDto = {
    nome: string,
    cnpj: string,
    email: string,
    telefone: string,
    grecaptcha: string,
};

function initFornecedor() {
    const fornecedorForm = document.querySelector<HTMLElement>(
        '.seja-fornecedor__secadastre-form'
    );
    if (fornecedorForm === null) {
        return;
    }
    Vue.use(Vuelidate);
    Vue.use(VueMask);

    let formMessages = {
        required: () => "O campo é obrigatório",
        maxLength: ({ $params }: any) => `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
        email: () => "O e-mail inserido é inválido",
    };
    let validationRules = {
        nome: {
            required,
            maxLength: maxLength(255)
        },
        cnpj: {
            required,
        },
        email: {
            required,
            email,
        },
        telefone: {
            required,
            maxLength: maxLength(15),
        },
        grecaptcha: {
            required,
        },
    };
    const getMask = (value: string) => {
        return value.length < 15 ? '(##) ####-####?#' : '(##) #####-####';
    }

    new Vue({
        el: fornecedorForm,
        data: {
            nome: '',
            cnpj: null,
            email: '',
            telefone: '',
            grecaptcha: null,

            loading: false,
            phoneMask: '(##) ####-####?#',
        },
        components: {
            VueRecaptcha
        },
        validations: validationRules,
        watch: {
            telefone(value) {
                this.phoneMask = getMask(value);
            },
        },
        methods: {
            validationMsg: validationMessage(formMessages, {}),
            reset() {
                this.nome = '';
                this.cnpj = '';
                this.email = '';
                this.telefone = '';
                this.grecaptcha = null;
                this.$refs.grecaptcha.reset();
                this.$v.$reset();
            },
            getFormData(): FornecedorDto {
                return {
                    nome: this.nome,
                    cnpj: this.cnpj.replaceAll(/[^0-9]/g, ''),
                    email: this.email,
                    telefone: this.telefone.replaceAll(/[^0-9]/g, ''),
                    grecaptcha: this.grecaptcha,
                };
            },
            warnSubmitSuccess(): void {
                Swal.fire({
                    title: "Sucesso!",
                    text: "Seu cadastro foi feito com sucesso.",
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'Obrigado(a)!',
                    customClass: {
                        popup: 'modal__content--sucesso',
                        header: 'modal__header modal__header--sucesso',
                        title: 'modal__title--sucesso',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__sucesso',
                    },
                    buttonsStyling: false
                });
            },
            warnSubmitFailure(): void {
                Swal.fire({
                    title: "Ops! Houve um problema",
                    text: "No momento não foi possível receber seu cadastro, tente novamente mais tarde!",
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false
                });
            },
            startLoading(): void {
                this.loading = true;
            },
            stopLoading(): void {
                this.loading = false;
            },
            formDataIsValid(): boolean {
                this.$v.$touch();
                return !this.$v.$invalid;
            },
            submitForm(): void {
                this.startLoading();
                if (this.formDataIsValid() === false) {
                    //
                } else {
                    axios.post( /* url */ '/api/fornecedores', /* data */ this.getFormData())
                        .then(this.warnSubmitSuccess)
                        .then(this.reset)
                        .catch(this.warnSubmitFailure);
                }
                this.stopLoading();
            },
            setGrecaptchaToken(grecaptchaToken: string): void {
                this.grecaptcha = grecaptchaToken;
            },
            warnRecaptchaExpiral(): void {
                Swal.fire({
                    title: "Aviso!",
                    text: "Sua verificação de Recaptcha expirou, tente novamente.",
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false
                });
            },
            warnRecaptchaError(): void {
                Swal.fire({
                    title: "Ops! Houve um problema",
                    text: "Houveram erros na verificação do seu Recaptcha!",
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false
                });
            },
        }
    })
}
initFornecedor();