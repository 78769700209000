import axios, { AxiosError } from 'axios';
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMessage } from "vuelidate-messages";
import Vue from 'vue';
import Vuelidate from "vuelidate";

type LoginDto = {
  cpf: string
};

type LoginResponseDto = {
  error: string,
  message: string,
} | null;

function initLogin() {
  let loginForm = document.querySelector<HTMLElement>(
    '.corretor__login-form'
  );
  if (loginForm === null) {
    return;
  }
  Vue.use(Vuelidate);

  let formMessages = {
    required: () => "O campo é obrigatório",
  };
  let validationRules = {
    cpf: {
      required
    },
  };

  new Vue({
    el: loginForm,
    data: {
      cpf: '',
      loading: false,
    },
    validations: validationRules,
    methods: {
      validationMsg: validationMessage(formMessages, {}),
      getFormData(): LoginDto {
        return {
          cpf: this.cpf.replaceAll(/[^0-9]/g, ''),
        };
      },
      getArquivosUrl(): string {
        let caminho: Array<string> = window.location.pathname.split('/');
        let origem: string = window.location.origin;
        let cidade: string = caminho[1];
        let cpf: string = this.cpf.replaceAll(/[^0-9]/g, '');
        return `${origem}/${cidade}/corretores/arquivos?cpf=${cpf}`;
      },
      redirectToArquivos(): void {
        window.location.assign( /* url */ this.getArquivosUrl());
      },
      warnSubmitFailure(error: AxiosError): void {
        let data = error.response?.data as LoginResponseDto;
        Swal.fire({
          title: "Ops! Houve um problema",
          text: data?.message ?? "Houveram problemas no Login",
          icon: "error",
          confirmButtonText: "Voltar",
          confirmButtonColor: "#86ba40"
        });
      },
      startLoading(): void {
        this.loading = true;
      },
      stopLoading(): void {
        this.loading = false;
      },
      formDataIsValid(): boolean {
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      handleSubmit(): void {
        this.startLoading();
        if (this.formDataIsValid() === false) {
          //
        } else {
          axios.get( /* url */ this.getArquivosUrl())
            .then(this.redirectToArquivos)
            .catch(this.warnSubmitFailure);
        }
        this.stopLoading();
      },
    }
  })
}
initLogin();